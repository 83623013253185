





































import { Component, Vue } from 'vue-property-decorator';
import { IService, IServiceUpdate } from '@/interfaces';
import {
  dispatchGetServices,
  dispatchGetService,
  dispatchUpdateService,
  readOneService,
} from '@/store/admin/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    VTextFieldWithValidation,
  },
})
export default class EditService extends Vue {
  public valid = true;
  public name: string = '';
  public code: string = '';
  public externalName: string | null = null;
  public imagesAllowed: boolean = false;
  public isEmail: boolean = false;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    await dispatchGetService(this.$store, Number(this.$router.currentRoute.params.id));
    this.reset();
  }

  public reset() {
    if (this.service) {
      this.name = this.service.name;
      this.code = this.service.code;
      this.externalName = this.service.external_name;
      this.imagesAllowed = this.service.images_allowed;
      this.isEmail = this.service.is_email;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const updatedService: IServiceUpdate = {};
      updatedService.name = this.name;
      updatedService.code = this.code;
      updatedService.external_name = this.externalName;
      updatedService.images_allowed = this.imagesAllowed;
      updatedService.is_email = this.isEmail;
      const result = await dispatchUpdateService(this.$store, { id: this.service!.id, service: updatedService });
      if (result) {
        this.$router.push('/main/admin/services');
      }
    }
  }

  get service() {
    return readOneService(this.$store)(+this.$router.currentRoute.params.id);
  }
}
